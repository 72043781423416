/* TaskCreationForm.css */

.form-container {
    margin: 0px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    margin-top: 5px;
}

.react-quill {
    background-color: white;
}

.date-priority-row {
    display: flex;
    justify-content: space-between;
}

.date-priority-row > div {
    flex-grow: 1;
    margin-right: 10px;
    margin-top: 15px;
}

.date-priority-row > div:last-child {
    margin-right: 0;
}

.submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.submit-button:hover {
    background-color: #0056b3;
}


.ck-editor__editable {
    min-height: 100px; /* Adjust the minimum height as needed */
  }
  