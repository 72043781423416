.profileHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  color: #545871;
}

.username {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #b5b5c3;
}

.location {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #545871;

  opacity: 0.8;
}

.aboutMeHeader {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #545871;
}

.aboutMeContent {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* or 136% */

  letter-spacing: -0.025em;

  color: #545871;
}

h6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 16px;

  color: #545871;

  opacity: 0.8;
}

h4 {
  font-family: "Roboto";
  font-style: bold;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height */

  color: #545871;
}
