.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  margin: 0;
  /* identical to box height, or 28px */

  /* Linear/Dark Blue */

  color: #344767;
}

.filter {
  background-color: #e8e4e4;
  padding: 15px;
  border-radius: 10px;
}

.searchHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height, or 175% */
  padding-top: 5px;
  color: #b5b5c3;
}

.username {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #b5b5c3;
}
.button {
  background: #51a6ff;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
.menuitem {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* Text color / Light Mode / Primary */

  color: rgba(0, 0, 0, 0.87);
}

.icon {
  color: #b5b5c3;
}

.profileHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 25px;

  color: #272d4e;
}

.location {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #94a2b3;
}

.cardHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  color: #94a2b3;
}
.cardBody {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #000000;
}
.loaderElement {
  margin-left: 50%;
  margin-top: 20%;
}

.cardData {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: #94a2b3;
}

.borderBox {
  padding: 5px;
}
