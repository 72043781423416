.views {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* coolGray/500 */

  color: #6b7280;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.imgClass {
  aspect-ratio: 18 / 10;
}
