.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 1px;

  color: #1e2022;
}

.subtitleText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 1px;

  color: #b1b5b9;
}

.subtextCardTitle {
  font-family: 'Roboto';

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.25px;

  /* NeutralLight（5）/nl500（Placeholder） */
  color: #98a2b2;
}

.subtextCardContent {
  font-family: 'Roboto';

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  letter-spacing: 1px;

  /* NeutralLight（5）/nl800（Title-Active） */
  color: #26292e;
}

.dotRed {
  width: 26px;
  height: 26px;
  background-color: #fff5f5;
  border-radius: 50%;
  display: inline-block;
}

.dotGreen {
  width: 26px;
  height: 26px;
  background-color: #f0fff4;
  border-radius: 50%;
  display: inline-block;
}

.dotPurple {
  width: 14px;
  height: 14px;
  background-color: #7244ae;
  border-radius: 50%;
  display: inline-block;
}

.dotBlue {
  width: 14px;
  height: 14px;
  background-color: #4299e1;
  border-radius: 50%;
  display: inline-block;
}

.distributionText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;

  color: #77838f;
}

.borderBox {
  border: '1px solid #344767';
  border-radius: '10px';
  padding: '10px';
}

.insightTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 140%;
  /* identical to box height, or 45px */

  /* Linear/Grey */
  color: #7b809a;
}

.stylesModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sylesPaper {
  background-color: white;
  outline: none;
  padding: 10px;
  height: auto;
}

.stylesModalHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boxed {
  border: '1px solid #344767';
  border-radius: '10px';
  padding-left: '10px';
  padding-right: '10px';
  padding-bottom: '10px';
}
