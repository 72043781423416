.menuitem {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  /* Text color / Light Mode / Primary */
  color: rgba(0, 0, 0, 0.87);
}
  
.icon {
  color: #b5b5c3;
}

.iconButton {
  height: 40px;
  background: #fafafb;
  color: #464e5f;
  border: black;
}
  
