.draft-wrapper-class {
  border: 1px solid #ccc;
}

.draft-editor-class {
  height: 40vh !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.hide-toolbar .draft-editor-class {
  height: 50vh !important;
}

.hide-toolbar-accepted .draft-editor-class {
  height: 25vh !important;
}

.draft-toolbar-class {
  border: 1px solid #ccc;
}

.hide-toolbar .draft-toolbar-class {
  display: none;
}

.hide-toolbar-accepted .draft-toolbar-class {
  display: none;
}
