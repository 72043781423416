/* general modals styling */
.styles_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_modal_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.styles_modal_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.syles_paper_list {
  background-color: white;
  outline: none;
  padding: 10px;
  height: 450px;
  overflow: auto;
}

/* expanded modal styling (used in post review > extended attachments */
.styles_expanded_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
}
.syles_expanded_paper_list {
  background-color: rgba(0, 0, 0, 1);
  outline: none;
  padding: 10px;
  height: 450px;
  overflow: auto;
}
.styles_expanded_modal_heading {
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 1);
}

.syles_paper {
  background-color: white;
  outline: none;
  padding: 10px;
  height: auto;
}

.separator {
  width: 100%;
  border-bottom: solid 1px;
  position: relative;
  margin: 30px 0px;
}

.separator::before {
  content: "OR";
  position: absolute;
  left: 50%;
  top: 50px;
  background-color: #fff;
  padding: 0px 10px;
}
.verticaDiv {
  display: flex;
  margin-top: 60px;
}

.ui.vertical.divider {
  position: absolute;
  z-index: 2;
  left: 50%;
  margin: 0;
  padding: 0;
  width: auto;
  height: 50%;
  line-height: 0;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 40px;
}

.ui.divider {
  margin: 1rem 0;
  line-height: 1;
  height: 0;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: rgba(0, 0, 0, 0.85);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.ui.vertical.divider:after,
.ui.vertical.divider:before {
  position: absolute;
  content: "";
  left: 45%;
  z-index: 3;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 0%;
  height: 100px;
}

.ui.vertical.divider:after {
  top: auto;
  bottom: 280px;
  height: 175px;
}

.ui.vertical.divider:before {
  top: -40%;
  height: 155px;
}

.ortext {
  height: 35px;
  width: 35px;
  background-color: #bbb;
  border-radius: 50%;
  margin-top: -30px;
}
.orSpan {
  margin-top: 18px;
  position: absolute;
  margin-left: -12px;
}

#listOfLists {
  overflow-y: scroll;
  height: auto;
  max-height: 300px;
  clear: both;
  padding-top: 10px;
  min-width: 500px !important;
  width: auto !important;
  max-width: 700px !important;
}

.searchBox {
  padding-top: 10px;
  min-width: 500px !important;
  width: 500px !important;
}

#searchUL {
  padding: 5px;
  list-style: none;
}

/*#searchInput {
    border-top: 1px solid #c4c4c4;
    border-radius: 5px;
}*/

li {
  cursor: pointer;
}

.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;

  background: #fff;
  border: 2px solid #666;
  color: #666;
  text-align: center;

  font: 32px Arial, sans-serif;
}

.react-tag-input {
  height: 3.4375em !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
}
textarea {
  padding: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
}

.faq .show {
  width: 90%;
  margin-left: 20px;
  height: auto !important;
  transition: 0.5s;
}
.faq-heading {
  /* width: 100%; */
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  margin-top: 10px;
}
.faq-section {
  width: 100% !important;
}
.rdw-editor-main {
  height: 200px !important;
}

#dos {
  min-height: 210px !important;
  max-height: 210px !important;
  overflow: auto !important;
}

#donts {
  min-height: 210px !important;
  max-height: 210px !important;
  overflow: auto !important;
}

.statusCell {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  color: #464e5f;
}

.dropdownItem {
  text-decoration: none;
  cursor: pointer;
}
