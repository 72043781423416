.heading {
  font-family: "Roboto";
  font-style: bold;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 28px */

  /* Linear/Dark Blue */

  color: #344767;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 10px;
}

.selectionBox {
  width: 100%;
  height: 100%;

  background: white;
  padding: 30px;
  color: #344767;
  border-radius: 10px;
}

.selectionBox.removePadding {
  padding: 0 !important;
}

.childBox {
  padding: 40px;
}
