.wrapperClass {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editorClass {
  background-color: lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbarClass {
  border: 1px solid #ccc;
}

.textEditor {
  border: 1px solid lightgray;
  padding: 10px;
  height: 300px;
  overflow: hidden auto;
}

.editor::-webkit-scrollbar {
  display: none;
}
.enhanceButton {
  background-color: #367BF5; 
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}


@media only screen and (max-width: 600px) {
  .dialogWrapper {
    padding: 20px 6px !important;
  }
}
