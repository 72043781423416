/* CSS */
.scoreContainer {
    background-color: rgba(217, 217, 217, 0.50);
    width: 335px !important;
    border-radius: 5px;
    position: absolute;
    height: 170px;
    float: right;
    margin: 0 auto;
}

.campaign-data-quality {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: #000;
  font-family: Roboto;
  font-style: normal;
  line-height: normal;
  padding-top: 5px;
}

.scoreText {
  font-weight: bold;
  color: #A5BD44;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

.grade {
  position: absolute;
  top: 40px;
  left: 75%;
  transform: translateX(-75%);
  color: #000;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.scoreValue {
    position: absolute;
    top: 100px; 
    left: 25%;
    color: #000;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 1.8px;
    width: 310px;
    margin: 0 auto;
    text-align: center;
}

/* Media Queries */
@media (max-width: 600px) {
 
  .grade {
    font-size: 36px !important;
    top: 50px !important;
  }

  .scoreValue {
    font-size: 24px;
    top: 90px !important;
    left: 60% !important;
  }
}

@media (max-width: 400px) {
  

  .grade {
    font-size: 24px;
    top: 20px;
  }

  .scoreValue {
    font-size: 18px;
    top: 50px;
  }
}

@media (max-width: 1600px) {
  /* Put your specific styles for the 13-inch screen here */
 
  .scoreOverlay {
    top: 186px !important;
    left: -25px !important;
  }
  .scoreChartOverlay {
    top: 85px !important;
    left: -152px !important;
  }
}

.scoreChart{
  float: left;
  display: block !important;
}

.tooltipImg {
  left: '0 !important';
  transform: 'translateX(-100%)';
}

.scoreOverlay {
  position: absolute;
  top: 165px;
  left: 0px;
  z-index: 99999;
}

.scoreChartOverlay {
  position: absolute;
  top: 65px;
  left: -125px; /* Adjust this value as needed to position the overlay */
  z-index: 99999;
}


.tableHeader {
  border: 1px solid black !important;
  font-weight: bold !important;
  white-space: nowrap;
  height: 20px;
  color: black;
}

.tableCol {
  border: 1px solid black !important;
  height: 20px;
  color: black;
  white-space: nowrap;
}