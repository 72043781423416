.staticButton {
    font-family: "Roboto";
    font-style: bold;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin: 0 !important;
    /* identical to box height */
}

.staticButton:disabled {
    color: white;
}

.toggleButton {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin: 30px;
}

.refreshButton {
    background: #fafafb;
    border: 1px solid #464e5f;
    color: #464e5f;
    border-radius: 10px;
    height: 30px !important;
}

.dotBlue {
    width: 26px;
    height: 26px;
    background-color: #d5ebfa;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}


.autocomplete {
    background: #ffffff;
    border: 0.4px solid #b5b5c3;
    border-radius: 10px;
}

.iconButton {
    height: 40px;
    background: #fafafb;
    color: #464e5f;
    border: black;
}

.background {
    background-color: #f2f2f4;
    overflow-x: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    /*padding: 10px;
    margin: 10px;*/
    width: 100%;
}

.bodycell {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: auto;

    color: #464e5f;
}

.statusCell {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    color: #464e5f;
}

.subbodycell {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.2rem;
    /* identical to box height */

    color: #b5b5c3;
}

.expandHeading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    padding-left: 100px;
    color: #b5b5c3;
}

.expandBody {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-left: 100px;

    color: #464e5f;
}

.campaignName {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 1000;
    font-size: 1.1rem;
    line-height: 1rem;
    color: #1461cc;
}

.dotGreen {
    width: 26px;
    height: 26px;
    background-color: #f0fff4;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.dotRed {
    width: 26px;
    height: 26px;
    background-color: #FFF5F5;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.refreshText {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 14px */
    display: flex;
    align-items: center;
    color: #464e5f;
}

.disclaimer {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 12px */
    display: flex;
    align-items: center;
    text-align: center;

    color: #b5b5c3;
}

.profileHeading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    margin-top: 5;
    margin: auto;
    color: #272d4e;
}

.cardHeading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
    color: #94a2b3;
}

.cardBody {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #000000;
}

.container {
    margin: auto;
    /* Setup */
}

.child {
    /* Center vertically and horizontally */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
}

.childContent {
    /* Center vertically and horizontally */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
}

.linkButton {
    color: #1461cc;
    cursor: pointer;
}

.dropdownItem {
    text-decoration: none;
    cursor: pointer;
}

.message {
    background-color: #ffffff;
    border: 2px solid #6b747a;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.button {
    background-color: #6b747a;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.button2 {
    background-color: #367BF5;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.button:hover {
    background-color: #8c9298;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.leftButton {
    text-align: left;
}

.centerButton {
    text-align: center;
}