.dropdownHeader {
  margin-bottom: -5px;
  margin-top: -5px;
  color: #747f8b;
  outline: none;
  display: block;
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
}
.readButton {
  color: #747f8b;
  display: block;
  font-size: 0.5em;
  padding: 10px;
}
.count {
  position: static;
  text-align: center;
  height: 25px;
  width: 25px;
  display: inline-block;
  line-height: 24px;
  margin-left: 8px;
  font-size: 12px;
  vertical-align: middle;
  background-color: #21b7b7;
  color: white;
  border-radius: 100%;
}

.dropdownBody {
  max-height: 292px;
  background: #e9f0f3;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 500ms ease;
}
.iconButtonBadge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  background: rgb(250, 53, 53);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: .75em;
  font-weight: bold;
}

.notification {
  background: #f4f6f8;
  border: 0px;
  border-style: solid;
  border-bottom-width: 1px;
  -moz-border-image: -moz-linear-gradient(right, #fff, #cedae0, #cedae0, #fff) 1
    20%;
  -o-border-image: -o-linear-gradient(right, #fff, #cedae0, #cedae0, #fff) 1 20%;
  border-image: linear-gradient(
      to right,
      #fff 0%,
      #cedae0 40%,
      #cedae0 60%,
      #fff 100%
    )
    1 20%;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  background: #e9f0f3;
}
.notificationText {
  display: table-cell;
  vertical-align: middle;
  color: #747f8b;
  cursor: pointer;
  font-size: 14px;
  padding-bottom: 5px;
}
.highlight {
  font-weight: bold;
}

.date {
  font-size: 12px;
  display: flex;
  text-align: start;
}

.wordWrap {
  white-space: pre-line; 
}

.time {
  font-size: 12px;
  font-style: italic;
}
.sideAction{
  font-size: 14px;
  text-align: right;
}