.widgetBackground {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}
.heading {
  font-family: 'Roboto';

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #007dff;
}

.count {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  white-space: nowrap;
  overflow: hidden;
  /* identical to box height */

  /* Dark */
  color: #454256;
}
