.thubnailCarousel .carousel .slide img {
  max-width: 200px;
  max-height: 200px;
}

.thubnailCarousel .carousel img {
  max-width: 200px;
  max-height: 200px;
}

.expandedCarousel .carousel .slide img {
  max-width: 700px;
  max-height: 700px;
}

.expandedCarousel .carousel img {
  max-width: 700px;
  max-height: 700px;
}
.error {
  border-color: red !important;
}

.modal-container {
  width: 58%; 
  margin: auto;
}

