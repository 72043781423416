.mat-option-text {
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.autocomplete-item__username {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-item__followers, .autocomplete-item__fullname, .autocomplete-item__username {
  display: inline-block;
  font-family: Roboto,sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222;
}

autocomplete-item__followers {
  width: 48px;
  text-align: right;
}

.autocomplete-item__followers, .autocomplete-item__fullname {
  color: #888 !important;
}
.autocomplete-item__followers, .autocomplete-item__fullname, .autocomplete-item__username {
  display: inline-block;
  font-family: Roboto,sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222;
}

.mat-autocomplete-panel.sampling-autocomplete-list .mat-option .mat-option-text img {
  margin-right: 0;
}

.autocomplete-item__picture {
  min-width: 42px;
  width: 42px;
  height: 42px;
}
.round-image {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid hsla(0,0%,86.7%,.25)!important;
}

.round-image {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid hsla(0,0%,86.7%,.25)!important;
}

.autocomplete-item__info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  justify-content: center;
  width: calc(100% - 90px);
  height: 48px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
}