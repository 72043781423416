.centerRowAligment {
    flex: 1;
    backgroundColor:"#a22a5f";
    flexDirection: 'row';
    alignItems:"center";
}
.centerColumnAligment {
    flex: 1;
    justifyContent: 'center';
    flexDirection: 'column';
    alignItems: 'center';
}
.fname {
    font-size: 24px;
    font-weight: 900;
    display: block;
    color: #4a4a4a;
    margin-bottom: 5px;
}

.general-info__holder{
    flex: 1;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.general-info__picture-holder {
    margin-right: 24px;
    position: relative;
}
.general-info__picture-frame{
    border: 1px solid #d0d6dd;
    border-radius: 50%;
    width: 78px;
    height: 78px;
    overflow: hidden;
}

.general-info__picture {
    height: auto;
    display: block;
    width: 100%;
}

.ng-star-inserted{
    box-sizing: border-box;
}
.verified ng-star-inserted{
    color: #151525;
}

.general-info{
    display: flex;
}

.td_list{
    font-weight: 700;
    min-width: 90px;
    display: table-cell;
    padding:25px;
        text-transform: capitalize;
}

.p_list{
    margin-bottom: 5px;
    margin-top: 0px;
    display: flex;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
    border-collapse: collapse;
    width:180px;
}
.tr_list{
        padding: 0 0 10px;
        vertical-align: top;
        font-weight: 400;
}

.MUIDataTableFilterList-root-28, .jss28, .jss112 {
        display: none !important;
}